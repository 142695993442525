import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function AboutMe() {
    return (
        <div className="border-b border-gray-400/10 relative isolate overflow-hidden bg-gray-800 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0" id='about'>
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-800 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-800">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            <p className="text-base font-semibold leading-7 text-indigo-200">About Me</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">Hi! I'm Ratha Nhil</h1>
                            <p className="mt-6 text-xl leading-8 text-gray-200">
                                I am a highly motivated individual with a passion for creativity and innovation. With a background in design
                                 and technology, I possess a unique set of skills that allow me to create captivating and seamless digital 
                                 experiences.  
                            </p>
                            <p className="mt-6 text-xl leading-8 text-gray-300">
                                 Combining my passion for design, technology, and storytelling, I aspire to create
                                  immersive digital experiences that leave a lasting impression on users. I believe in the power of clean 
                                  code, attention to detail, and the seamless integration of design and functionality. I strive to create 
                                  web applications that not only look
                                 visually stunning but also provide intuitive and user-friendly experiences.

                            </p>
                            <p className="mt-6 text-xl leading-8 text-gray-300">
                                 My goal is to inspire and connect with users through my work, leaving them with a sense of awe and wonder.
                                  I am driven by the belief that technology has 
                                 the potential to make a profound impact on people's lives, and I am dedicated to harnessing
                                  that power to create exceptional digital experiences.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        src="https://spaces-wp.imgix.net/2016/06/coding-in-the-classroom.png?auto=compress,format&q=50"
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
