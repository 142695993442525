const posts = [
  {
    id: 1,
    title: 'Trendy Team',
    href: 'https://github.com/orgs/TrendyTeam/repositories',
    description:
      'Trendy is an Ecomerce app, which is a mobile platform built in Kotlin',
    imageUrl:
      'https://plus.unsplash.com/premium_photo-1681488243109-14af9194e149?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDF8fHNob3BpbmclMjBtb2JpbGUlMjBhcHB8ZW58MHx8MHx8fDA%3D',
    date: 'Oct 1, 2023',
    category: { title: 'Android App', href: 'https://github.com/orgs/TrendyTeam/repositories' },
    author: {
      name: 'Michael Foster',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 2,
    title: 'Back-End and Data Mining',
    href: 'https://github.com/RathaMnusSmos/BackEnd-Trendy',
    description:
      'Trendy BackEnd is an Ecomerce API, which is a API platform built in NodeJs EpressJs with MySql and Apply Apriori Algorithm which is the Machine Learning algorithm.',
    imageUrl:
      'https://images.unsplash.com/photo-1613068687893-5e85b4638b56?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fGJhY2tlbmR8ZW58MHx8MHx8fDA%3D',
    date: 'Jan 7, 2024',
    category: { title: 'BackEnd API', href: 'https://github.com/RathaMnusSmos/BackEnd-Trendy' },
    author: {
      name: 'Michael Foster',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 3,
    title: 'Trendy Admin',
    href: 'https://github.com/Trendy-Shop-by-ITE/trendy-admin',
    description:
      'Trendy Admin is an Ecomerce Admin, which is a Website platform built in ReactJs',
    imageUrl:
      '/trendy_admin.png',
    date: 'Dec 1, 2023',
    category: { title: 'Web Admin', href: 'https://github.com/Trendy-Shop-by-ITE/trendy-admin' },
    author: {
      name: 'Michael Foster',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 4,
    title: 'Trendify',
    href: 'https://github.com/Trendy-Nickii/Trendy-Android',
    description:
      'Trendify is an Ecomerce App, which is a Website platform built in Kotlin.',
    imageUrl:
      'https://s3-figma-hubfile-images-production.figma.com/hub/file/carousel/img/cce5943002aa8733b35077981b1a8576c8669c90',
    date: 'Mar 1, 2024',
    category: { title: 'Android App', href: 'https://github.com/Trendy-Nickii/Trendy-Android' },
    author: {
      name: 'Michael Foster',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  // {
  //   id: 5,
  //   title: 'CUSCEN ERP',
  //   href: 'https://play.google.com/store/apps/details?id=com.sunfix.cuscen&hl=en&gl=US',
  //   description:
  //     'CUSCEN is an ERP application that I developed during my first real-world experience as an Android developer..',
  //   imageUrl:
  //     '/cuscen.png',
  //   date: 'Aug 14, 2023',
  //   category: { title: 'Android App', href: 'https://play.google.com/store/apps/details?id=com.sunfix.cuscen&hl=en&gl=US' },
  //   author: {
  //     name: 'Michael Foster',
  //     role: 'Co-Founder / CTO',
  //     href: '#',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  // },
  // More posts...
]

export default function Card() {
  return (
    <div className="bg-gray-800 border-b border-gray-400/10 py-24 sm:py-32" id="card">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">Skill and Experience</h2>
      
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-400">
                    {post.date}
                  </time>
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-100 group-hover:text-gray-400">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-300">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
