import AboutMe from './AboutMe';
import './App.css';
import Card from './Card';
import Contact from './Contact';
import Footer from './Footer';
import HeroSection from './HeroSection';
import { Analytics } from "@vercel/analytics/react"

function App() {
  document.addEventListener("contextmenu", (e) => e.preventDefault());

  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.key === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };
  return (
    <div>
      <Analytics />
      <HeroSection />
      <AboutMe />
      <Card />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
